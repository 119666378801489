import {
    Controller
} from "stimulus"
import {
    Dropzone
} from "dropzone";

export default class extends Controller {

    static targets = [
        "dropzone",
        "filename",
        "mime",
        "location",
        "thumbnail",
        "previewTemplate"
    ]

    connect() {
        this.dropzone = new Dropzone(this.dropzoneTarget, {
            'url': this.dropzoneTarget.getAttribute('action'),
            init: function () {
                this.on("addedfile", function (file) {
                    if (this.currentFile) {
                        this.removeFile(this.currentFile)
                    }
                    file.previewElement.addEventListener("click", function () {
                        this.hiddenFileInput.click()
                    }.bind(this))
                })
                this.on("success", function (file) {
                    this.currentFile = file
                })
            },
            chunksUploaded: function (file, done) {
                window.axios.get(this.dropzoneTarget.getAttribute('action'))
                    .then(response => {
                        done()
                        this.filenameTarget.value = response.data.filename
                        this.mimeTarget.value = response.data.mime
                        this.locationTarget.value = response.data.location
                        var formId = this.data.get('submitform')
                        if (formId) {
                            $(formId).submit()
                        }
                    });
            }.bind(this),
            previewTemplate: this.previewTemplateTarget.innerHTML,
            dictDefaultMessage: this.data.get('defaultmessage'),
            thumbnailWidth: null,
            thumbnailHeight: null,
            chunking: true,
            forceChunking: true,
            acceptedFiles: this.data.get('acceptedfiles'),
            maxFiles: 1,
            params: {
                "_token": window.token,
            }
        })
        var existingImage = this.data.get('image')
        if (existingImage) {
            var currentFile = {
                'name': existingImage,
                'size': 1000
            }
            this.dropzone.emit('addedfile', currentFile)
            this.dropzone.emit('thumbnail', currentFile, existingImage)
            this.dropzone.emit('success', currentFile)
            this.dropzone.emit('complete', currentFile)
        }
    }
}
